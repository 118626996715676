import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Nav
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import { UserContext } from "../context/ToggleContext";

const Dashboardheader = () => {
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
        Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
      );
  const [lastparturl, setlastparturl] = useState([]);
  useEffect(() => {
    dynamicLink();

    if (!loginData.id) {
      window.location.href = `${config.baseUrl}`;
    }
  }, []);

  const dynamicLink = () => {
    let url = window.location.href;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    setlastparturl(lastPart);
  };

  return (
    <>
      <div className="tabs">
        <Container>
          <Nav
            variant="pills"
            className="justify-content-sm-between justify-content-center nav nav-pills"
            activeKey="Stepmint/dashboard"
          >
            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}dashboard`}
                className={`${
                  lastparturl == "dashboard" ? "active" : ""
                } custom-link`}
              >
                Dashboard
              </Link>
            </Nav.Item>
            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}packageplan`}
                className={`${
                  lastparturl == "packageplan" ? "active" : ""
                } custom-link`}
              >
                Subscriptions
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}useractivity`}
                className={`${
                  lastparturl == "useractivity" ? "active" : ""
                } custom-link`}
              >
                User Activity
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}referral`}
                className={`${
                  lastparturl == "referral" ? "active" : ""
                } custom-link`}
              >
                Referral
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}matchingbonus`}
                className={`${
                  lastparturl == "matchingbonus" ? "active" : ""
                } custom-link`}
              >
                Community Rewards
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}wallethistory`}
                className={`${
                  lastparturl == "wallethistory"
                    ? "active "
                    : lastparturl == "deposit"
                    ? "active "
                    : lastparturl == "withdraw"
                    ? "active "
                    : ""
                } custom-link`}
              >
                Wallet
              </Link>
            </Nav.Item>

            <Nav.Item className="nav-item-with-submenu custom-nl">
              <Link
                className={`${
                  lastparturl === "ldp2" || lastparturl === "ldp1"
                    ? "active"
                    : ""
                } custom-link`}
              >
                Events
              </Link>
              <div className="submenu">
                <Nav className="submenu-items d-flex flex-column gap-1 ">
                  <Nav.Item className="submenu-item">
                    <Link to={`${config.baseUrl}ldp1`} className="submenu-link">
                      LDP 1
                    </Link>
                  </Nav.Item>
                  <Nav.Item className="submenu-item">
                    <Link to={`${config.baseUrl}ldp2`} className="submenu-link">
                      LDP 2
                    </Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Nav.Item>
          </Nav>
        </Container>
        <hr className="mt-0 mb-4" />
      </div>
    </>
  );
};
export default Dashboardheader;
