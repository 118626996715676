import { getRequest, postRequest, putRequest, deleteRequest, postRequestFormData } from "../directives/helper";

export const getFaqsAction = (data) => {
   return getRequest('getFAQslist', data).then(res => { return res.data })
}

export const RegisterAction = (data) => {
    return postRequest('weblogin', data).then(res => { return res.data })
 }
 export const ProfileUpdateAction = (data) => {
   return putRequest('profileupdate', data).then(res => { return res.data })
}

export const LdpUpdateAction = (data) => {
   return putRequest('ldpUpdate', data).then(res => { return res.data })
}

export const Ldp2UpdateAction = (data) => {
   return putRequest('ldp2Update', data).then(res => { return res.data })
}

export const GetOtpAction = (data) => {
   return getRequest('checkLDP1', data).then(res => { return res.data })
}

export const GetLdp2Action = (data) => {
   return getRequest('checkLDP2', data).then(res => { return res.data })
}

export const SendOtpAction = (data) => {
   return postRequest('sendLdpQualifierOtp', data).then(res => { return res.data })
}

export const VerifyOtpAction = (data) => {
   return postRequest('verifyLdpQualifierOtp', data).then(res => { return res.data })
}

export const VerifyQRCodeAction = (data) => {
   return postRequest('verifyQRCode', data).then(res => { return res.data })
}


export const ContactUsAction = (data) => {
   return postRequest('contactus', data).then(res => { return res.data })
}
export const checkmintingofNFTAction = (data) => {
   return getRequest(`checkmintingofNFT?nftId=${data}`).then(res => { return res.data })
}
export const LDPUserlist = (data) => {
   return getRequest('ldpList', data).then(res => { return res.data })
}
export const ldpListUpdateAction = (data) => {
   return postRequest('ldpListUpdate', data).then(res => { return res.data })
}
 export const UserProfile = (data) => {
    return getRequest('userprofile', data).then(res => { return res.data })
 }
 export const deleteUserProfileAction = (data) => {
   return getRequest('deleteuser', data).then(res => { return res.data })
}
 export const getcmsAction = (data) => {
   return getRequest(`getcms?show=${data}`).then(res => { return res.data })
}
 export const ReferralUserList = (data) => {
   return getRequest('referraluserlist', data).then(res => { return res.data })
}
export const MatchingTeamAndNodeList = (data) => {
   return getRequest('matchingteamandnodelist', data).then(res => { return res.data })
}

export const userWeeklyStatisticsAction = (data) => {
   return getRequest('userweeklystatistics', data).then(res => { return res.data })
}

export const userMonthlyStatisticsAction = (data) => {
   return getRequest('usermonthlystatistics', data).then(res => { return res.data })
}

export const stepRewardHistoryAction = (data) => {
   return getRequest('steprewardhistory', data).then(res => { return res.data })
}

export const walletStatisticsAction = (data) => {
   return getRequest('walletstatistics', data).then(res => { return res.data })
}

export const userPerDayStatisticsAction = (data) => {
   return getRequest('userperdaystatistics', data).then(res => { return res.data })
}

export const balanceTransferStatisticsAction = (data) => {
   return getRequest('balancetransferstatistics', data).then(res => { return res.data })
}

 export const DisplaySubscription = (data) => {
   return getRequest('displayallsubscriptionplan', data).then(res => { return res.data })
}

export const DisplaySelectedSubscriptionPlanId = (data) => {
   return getRequest(`displayselectedsubscriptionplan?id=${data}`).then(res => { return res.data })
}

export const purchasesubscription = (data) => {
   return getRequest(`purchasesubscription?planId=${data}`).then(res => { return res.data })
}

export const DisplayAllSubscriptionPlanForUser = (data) => {
   return getRequest('displayallsubscriptionplanforuser', data).then(res => { return res.data })
}

export const UserPurchasedPlan = (data) => {
   return getRequest('userpurchasedplan', data).then(res => { return res.data })
}

export const DashboardStatistics = (data) => {
   return getRequest('dashboardstatistics', data).then(res => { return res.data })
}


export const showsteplevelrewardAction = (data) => {
   return getRequest('showsteplevelreward', data).then(res => { return res.data })
}

export const MntDepositHistory = (data) => {
   return getRequest('mntdeposithistory', data).then(res => { return res.data })
}

export const ShowReferralHistory = (data) => {
   return getRequest(`showreferralORmatchingbonushistory?type=${data}`).then(res => { return res.data })
}

export const DepositWithdrwalHistory = (data) => {
   return getRequest(`depositwithdrwalhistory`,data).then(res => { return res.data })
}

export const TransferToMainWalletAction = (data) => {
   return getRequest(`transfertomainwallet`,data).then(res => { return res.data })
}

export const WithdrawalHistoryAction = (data) => {
   return getRequest(`withdrawalhistory`,data).then(res => { return res.data })
}

export const addWithdrawRequestAction = (data) => {
   return postRequest(`addWithdrawRequest`,data).then(res => { return res.data })
}

export const addmainwalletwithdrawrequestAction = (data) => {
   return postRequest(`addmainwalletwithdrawrequest`,data).then(res => { return res.data })
}
export const sendOtpForVerificationAction = (data) => {
   return postRequest(`sendEmailOtp`,data).then(res => { return res.data })
}
export const verifyOtpForVerificationAction = (data) => {
   return postRequest(`verifyEmailAndOtp`,data).then(res => { return res.data })
}

export const exportLoyaltyBounsData = (data) => {
   return postRequest('downloadLoyaltyBonusCsv', data).then(res => { return res.data })
}

export const getTransactionSummary = (data) => {
   return getRequest('getTransactionSummary', data).then(res => { return res.data })
}