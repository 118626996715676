import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Tab,
  Modal,
} from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import {
  DepositWithdrwalHistory,
  walletStatisticsAction,
  sendOtpForVerificationAction,
  verifyOtpForVerificationAction,
} from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Cookies from "js-cookie";
import { UserContext } from "../context/ToggleContext";
import { FaEye } from "react-icons/fa";
import Passbook from "./Passbook/Passbook";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@material-ui/core";

const Wallethistory = () => {
  const navigate = useNavigate();
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );
  const [wallethistorydata, setwallethistorydata] = useState({});
  const [balanceTransferHistory, setbalanceTransferHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('withdrawrequest');
  const [viewbalance, setviewbalance] = useState(false);

  const [wallethistorydatalist, setwallethistorydatalist] = useState([]);
  const [message, setmessage] = useState(
    "You have insufficient funds to transfer"
  );

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    console.log(selected, "selected");
    setCurrentPage(selected);
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  // Handle view balance click
  const handleViewBalance = () => {
    setviewbalance(!viewbalance);
  };


  const totalPages = Math.ceil(balanceTransferHistory.length / itemsPerPage);
  const totalPageswithdraw = Math.ceil(
    wallethistorydatalist.length / itemsPerPage
  );

  const isOnFirstPage = currentPage == 0;
  const isOnLastPage = currentPage == totalPages - 1;
  const isOnLastPageWithdraw = currentPage == totalPageswithdraw - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const wallethistorydatalistSlice = wallethistorydatalist.slice(
    startIndex,
    endIndex
  );

  useEffect(() => {
    wallethistory();
    wallethistorylist();
  }, []);


  const wallethistory = async () => {
    let res2 = await walletStatisticsAction();

    if (res2.success) {
      setwallethistorydata(res2.data);
    } else {
      setwallethistorydata([]);
    }
  };


  const wallethistorylist = async () => {
    let res = await DepositWithdrwalHistory();
    if (res.success) {
      setwallethistorydatalist(res.data);
    } else {
      setwallethistorydatalist([]);
    }
  };
  // --------------------------------------------------------Email verification Modal code-----------------------------------------
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpIs, setOtpIs] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [loding, setloding] = useState(false);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  useEffect(() => {
    let otp1 = otp[0] + otp[1] + otp[2] + otp[3];
    console.log(otp1);
    setOtpIs(otp1);
  }, [otp]);

  const handleVerificationModal = () => {
    setshowModal(true);
    navigate(`${config.baseUrl}withdraw`);
  };

  const sendOtpForVerification = async () => {
    try {
      const data = {
        email: email,
      };
      let res = await sendOtpForVerificationAction(data);
      if (res.success) {
        setloding(true);
        toast.success(res.msg);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const verifyOtpForVerification = async () => {
    try {
      const data = {
        email: email,
        otp: otpIs,
      };
      let res = await verifyOtpForVerificationAction(data);
      if (res.success) {
        toast.success(res.msg);
        loginData.is_email_verify = 1;
        Cookies.set("loginSuccessStepMrMint", JSON.stringify(loginData));
        setTimeout(() => {
          navigate(`${config.baseUrl}withdraw`);
        }, 4000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" alt="" />
        <img src="assets/images/shape1.svg" className="shape1" alt="" />
        <img src="assets/images/shape2.svg" className="shape2" alt="" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-4">
            <Container className="">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h2 className="text-white">Wallet Overview</h2>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div className="mb-5 ">
            <Container className="">
              <Row>
                <Col lg={4} className="mb-3">
                  <div className="wallet_card">
                    <div className="card-view3 p-4 ">
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="text-light-grey mb-3">
                              Stepmint Wallet
                            </h4></div>
                          <div className="mb-3">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={viewbalance}
                                  onChange={handleViewBalance}
                                />
                              }
                              label={<p className="mb-0" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }} style={{ fontSize: 10, width: 30 }}>{viewbalance ? "Hide" : "Show"}</p>}
                              style={{ color: "white" }}
                            />
                          </div>
                        </div>

                        <>
                          <span className="">
                            <img
                              src="assets/images/mntcoin.png"
                              width="20px"
                            />
                            &nbsp;{viewbalance ? wallethistorydata.stepBalance > 0 ? wallethistorydata.stepBalance : 0 : '********'} MNT
                          </span>

                        </>
                        <div className="w-btn-list justify-content-start mt-4 h-100">
                          <div >
                            {loginData.is_email_verify == 1 ? (
                              <a href={`${config.baseUrl}withdraw`}>
                                <button
                                  className="w-btn card-view"
                                // style={{cursor:"not-allowed"}}
                                >
                                  <div className="">
                                    <span className="circle-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="13"
                                        viewBox="0 0 23 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M21.3818 5.5H1.38184M1.38184 9.5H4.92851C5.4687 9.5 5.7388 9.5 5.99997 9.54655C6.23179 9.58788 6.45794 9.65635 6.67374 9.75056C6.91688 9.85669 7.14161 10.0065 7.59107 10.3062L8.1726 10.6938C8.62206 10.9935 8.8468 11.1433 9.08993 11.2494C9.30574 11.3437 9.53188 11.4121 9.7637 11.4534C10.0249 11.5 10.295 11.5 10.8352 11.5H11.9285C12.4687 11.5 12.7388 11.5 13 11.4534C13.2318 11.4121 13.4579 11.3437 13.6737 11.2494C13.9169 11.1433 14.1416 10.9935 14.5911 10.6938L15.1726 10.3062C15.6221 10.0065 15.8468 9.85669 16.0899 9.75056C16.3057 9.65635 16.5319 9.58788 16.7637 9.54655C17.0249 9.5 17.295 9.5 17.8352 9.5H21.3818M1.38184 4.2L1.38184 13.8C1.38184 14.9201 1.38184 15.4802 1.59982 15.908C1.79157 16.2843 2.09753 16.5903 2.47385 16.782C2.90168 17 3.46173 17 4.58184 17L18.1818 17C19.3019 17 19.862 17 20.2898 16.782C20.6661 16.5903 20.9721 16.2843 21.1639 15.908C21.3818 15.4802 21.3818 14.9201 21.3818 13.8V4.2C21.3818 3.0799 21.3818 2.51984 21.1639 2.09202C20.9721 1.7157 20.6661 1.40974 20.2898 1.21799C19.862 1 19.3019 1 18.1818 1L4.58184 1C3.46173 1 2.90168 1 2.47386 1.21799C2.09753 1.40973 1.79157 1.71569 1.59982 2.09202C1.38184 2.51984 1.38184 3.07989 1.38184 4.2Z"
                                          stroke="#F4F4F4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    &nbsp;&nbsp; <small>Withdraw</small>
                                  </div>
                                </button>
                              </a>
                            ) : (
                              <button
                                onClick={handleVerificationModal}
                                className="w-btn card-view"
                              // style={{cursor:"not-allowed"}}
                              >
                                <div className="">
                                  <span className="circle-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="13"
                                      viewBox="0 0 23 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M21.3818 5.5H1.38184M1.38184 9.5H4.92851C5.4687 9.5 5.7388 9.5 5.99997 9.54655C6.23179 9.58788 6.45794 9.65635 6.67374 9.75056C6.91688 9.85669 7.14161 10.0065 7.59107 10.3062L8.1726 10.6938C8.62206 10.9935 8.8468 11.1433 9.08993 11.2494C9.30574 11.3437 9.53188 11.4121 9.7637 11.4534C10.0249 11.5 10.295 11.5 10.8352 11.5H11.9285C12.4687 11.5 12.7388 11.5 13 11.4534C13.2318 11.4121 13.4579 11.3437 13.6737 11.2494C13.9169 11.1433 14.1416 10.9935 14.5911 10.6938L15.1726 10.3062C15.6221 10.0065 15.8468 9.85669 16.0899 9.75056C16.3057 9.65635 16.5319 9.58788 16.7637 9.54655C17.0249 9.5 17.295 9.5 17.8352 9.5H21.3818M1.38184 4.2L1.38184 13.8C1.38184 14.9201 1.38184 15.4802 1.59982 15.908C1.79157 16.2843 2.09753 16.5903 2.47385 16.782C2.90168 17 3.46173 17 4.58184 17L18.1818 17C19.3019 17 19.862 17 20.2898 16.782C20.6661 16.5903 20.9721 16.2843 21.1639 15.908C21.3818 15.4802 21.3818 14.9201 21.3818 13.8V4.2C21.3818 3.0799 21.3818 2.51984 21.1639 2.09202C20.9721 1.7157 20.6661 1.40974 20.2898 1.21799C19.862 1 19.3019 1 18.1818 1L4.58184 1C3.46173 1 2.90168 1 2.47386 1.21799C2.09753 1.40973 1.79157 1.71569 1.59982 2.09202C1.38184 2.51984 1.38184 3.07989 1.38184 4.2Z"
                                        stroke="#F4F4F4"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  &nbsp;&nbsp;<small>Withdraw</small>
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="chart_icon" style={{ zIndex: -1 }}>
                        <img src="assets/images/chart_wave.png" />
                      </div>
                    </div>
                  </div>
                </Col>

                {wallethistorydata.mainBalance <= 0 ||
                  !wallethistorydata.mainBalance ? (
                  <Col lg={4} className="mb-3"></Col>
                ) : (
                  <Col lg={4} className="mb-3">
                    <div className="wallet_card">
                      <div className="card-view3 p-3">
                        <div className="">
                          <h4 className="text-light-grey mb-3">Main Wallet</h4>
                          <div className="fs-5">
                            <img
                              src="assets/images/mntcoin.png"
                              width="25px"
                              alt=""
                            />
                            &nbsp; {wallethistorydata.mainBalance} MNT
                          </div>
                        </div>

                        <div className="chart_icon">
                          <img src="assets/images/chart_wave.png" />
                        </div>
                      </div>
                    </div>
                  </Col>
                )}


              </Row>
            </Container>
          </div>
          {/* ---------- */}

          <div className="transaction mb-5">
            <Container>
              <h3 className="text-white mb-4">Statistics</h3>
              <div className="transaction_detail">
                <Row>
                  <Col lg={4} className="mb-4 d-flex r">
                    <div className="listbox flex-fill d-flex justify-content-between">
                      <Row className="align-items-center justify-content-between w-100">
                        <Col lg={8} xs={6} className="align-items-center text-nowrap">
                          <p className="mb-0">Referral Earning</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0">
                            ${wallethistorydata.referralAmount}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4} className="mb-4 d-flex">
                    <div className="listbox flex-fill">
                      <Row className="align-items-center">
                        <Col lg={8} xs={6}>
                          <p className="mb-0">Community Rewards</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0" style={{ wordWrap: "break-word" }}>
                            ${wallethistorydata.matchingAmount}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4} className="mb-4 d-flex">
                    <div className="listbox flex-fill d-flex justify-content-between">
                      <Row className="align-items-center justify-content-between w-100">
                        <Col lg={6} xs={7}>
                          <p className="mb-0 text-nowrap">Step Reward</p>
                        </Col>
                        <Col lg={6} xs={5} className="text-right">
                          <p className="text-white mb-0">
                            {wallethistorydata.stepReward} MNT
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

              </div>
            </Container>
          </div>
          {/* ---------- */}
          <div className="latest_transaction mb-5">
            <Container>
              {/* <h3 className="text-white mb-4">Transactions</h3> */}
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="withdrawrequest"
                onSelect={(key) => setActiveTab(key)}
              >
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link
                          onClick={CurrentPageResetFunc}
                          eventKey="withdrawrequest"
                          className="card-view1 customnav-link"
                        >
                          Transactions
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          onClick={CurrentPageResetFunc}
                          eventKey="passbook"
                          className="card-view1 customnav-link"
                        >
                          Transactions Summary
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content >
                      <Tab.Pane eventKey="withdrawrequest" >
                        <div className="table-responsive">
                          <table width="100%">
                            <thead>
                              <tr className="table-head">
                                <th>S.No</th>
                                <th>Amount</th>
                                <th>Withdraw</th>
                                <th>Status</th>
                                <th>Requested Date</th>
                                <th>Approve Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {wallethistorydatalist.length == 0 ? (
                                <tr className="tr_list">
                                  <td colspan="6">No Data Available</td>
                                </tr>
                              ) : (
                                wallethistorydatalistSlice.map(
                                  (item, index) => (
                                    <tr class="tr_list">
                                      <td>
                                        {index + 1 + currentPage * itemsPerPage}
                                      </td>{" "}
                                      <td>{item.usd_amount}&nbsp;MNT</td>
                                      <td>{item.withdraw_type}</td>
                                      <td
                                        style={{
                                          color: `${item.status == "Rejected"
                                            ? "Red"
                                            : item.status == "Approved"
                                              ? "Green"
                                              : "#D3D3D3"
                                            }`,
                                        }}
                                      >
                                        {item.status}
                                      </td>
                                      <td>
                                        {item.status == "Deposited"
                                          ? item.time?.slice(0, 10)
                                          : item?.requestDate?.slice(0, 10)}
                                      </td>
                                      <td>
                                        {item.status == "Rejected"
                                          ? ""
                                          : item?.approvedDate?.slice(0, 10)}
                                      </td>
                                    </tr>
                                  )
                                )
                              )}
                            </tbody>
                          </table>
                          {wallethistorydatalist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageWithdraw ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageWithdraw ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  wallethistorydatalist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="passbook">
                        {activeTab === 'passbook' && <Passbook />}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>


      {/* Modal for Email Verification  */}
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        backdrop="static"
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title>
            <h5 className="mb-0">First verify your email </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    disabled={loding}
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {loding == true && (
              <Row className="mb-3">
                <Form.Label>Enter otp</Form.Label>
                {otp.map((value, index) => (
                  <Col key={index} xs={3}>
                    <Form.Control
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </Modal.Body>
        {loding == false ? (
          <Button onClick={sendOtpForVerification}>Send OTP</Button>
        ) : (
          <Button onClick={verifyOtpForVerification}>Verify OTP</Button>
        )}
      </Modal>
    </>
  );
};
export default Wallethistory;
