import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
  Form
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import { TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const loginData = (!Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' })) ? [] : JSON.parse(Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' }));

  const [connectWalletAddress, setConnectWalletAddress] = useState('');
  const [form, setForm] = useState({ email: '', password: '', confirm_password: '', referral_address: '', termscondition: false })
  const [isCaptcha, setisCaptcha] = useState(0);
  const [validationError, setvalidationError] = useState({ firstNameError: '', lastNameError: '', emailError: '' });
  let { referral_address } = useParams();
  const navigate = useNavigate();

  let captchaKey = config.TEST_SITE_KEY;
  let url1 = window.location.href;
  if (url1 == 'https://mrmint.biz/signup/' || url1 == 'https://mrmint.biz/signup' || url1 == 'http://mrmint.biz/signup/' || url1 == 'http://mrmint.biz/signup' || url1 == 'mrmint.biz/signup/' || url1 == 'mrmint.biz/signup/') {
    captchaKey = config.TEST_SITE_KEY_BIZ;
  }

  let url = window.location.href;
  let result = url.split('ref=');
  let Param = result[1];

  if (!referral_address && Param) {
    let decodeAddress = Base64.decode(Param);
    referral_address = decodeAddress
  } else if (!Param) {
    referral_address = referral_address;
  }





  useEffect(() => {
    if (loginData.id) {
      window.location.href = `${config.baseUrl}`
    }

    if (referral_address) {
      setForm((old) => {
        return { ...old, 'referral_address': referral_address }
      })
    }

    setTimeout(async () => {
      if (window.ethereum) {
        const { ethereum } = window;
        setConnectWalletAddress(ethereum.selectedAddress);
      }
    }, 200);

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        setConnectWalletAddress(accounts[0]);
      })
    }

  }, []);

  const captcha = async => {
    setisCaptcha(1);
    setvalidationError((old) => {
      return { ...old, ['isCaptchaError']: '' }
    })
  }

  const connectMetasmask = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0]);
      } else {
        toast.error('Please use Dapp browser!!');
      }
    } catch (error) {
      if (error.code == -32002) {
        toast.error('Request Already Processing, Please check your Meta Mask wallet');
      }
    }
  }



  const inputHandler = (e) => {
    // alert(1)
    const { name, value, id } = e.target
    if (value != '') {
      setvalidationError((old) => {
        return { ...old, [id]: '' }
      })
    }

    setForm((old) => {
      return { ...old, [name]: value }
    })
  }

  function validate() {
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let termsconditionError = "";
    let isCaptchaError = "";

    if (form.email === '') {
      emailError = "Email is required."
    }
    if (form.password === '') {
      passwordError = "Password is required."
    }
    if (form.password === '') {
      passwordError = "Password is required."
    }
    if (form.password.length <8) {
      passwordError = "Password Should be 8 characters long."
    }
    if (!/[a-z]/.test(form.password)) {
      passwordError = "Password should be at least 1 small alphabet is required."
    }
    if (!/[A-Z]/.test(form.password)) {
      passwordError = "Password should be at least 1 capital alphabet is required."
    }
    if (!/[0-9]/.test(form.password)) {
      passwordError = "Password should be at least 1 numeric character is required."
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(form.password)) {
      passwordError = "Password should be at least 1 special character is required."
    }
    if (form.confirm_password === '') {
      confirmPasswordError = "Confirm password is required."
    }
    if (form.password != form.confirm_password && (form.password && form.confirm_password)) {
      confirmPasswordError = "Password and confirm password does not match."
    }
    // if (form.termscondition === false) {
    //   termsconditionError = "You must agree to the terms and conditions."
    // }
    // if (isCaptcha == 0) {
    //     isCaptchaError = "Captcha required."
    // }

    if (emailError || passwordError || confirmPasswordError 
      // ||
      //  termsconditionError
       ) {
      setvalidationError({
        emailError, passwordError, confirmPasswordError, 
        // termsconditionError
      })
      return false
    } else {
      return true
    }
  }

  const SubmitForm = async (e) => {
    e.preventDefault()
    try {
      const isValid = validate();
      if (!isValid) {
      }
      else {
        if (!connectWalletAddress || connectWalletAddress == '') {
          toast.error('Please connect your metamask wallet!!');
          return false;
        } else {
          form.bnb_address = connectWalletAddress;
          form.referral_address = form.referral_address ? form.referral_address : referral_address ? referral_address : config.adminAddress;
          // let res = await RegisterAction(form);
          await axios({
            method: 'post',
            url: `${config.serverPath}/userRegister`,
            data: form
          }).then(res => {
            if (res.data.success) {
              toast.success(res.data.msg);
              setTimeout(() => {
                navigate(`${config.baseUrl}`)
              }, 2000);
            } else {
              toast.error(res.data.msg);
            }
          })
          // if (res.success) {
          //   toast.success(res.msg);
          //   setTimeout(() => {
          //     window.location.href = `${config.baseUrl}login`
          //   }, 2000);
          // } else {
          //   toast.error(res.msg);
          // }
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("We are sorry, We're experiencing technical difficulties.")
    }
  }
  return (
    <>
      <Header />
      <Toaster />

      <section className="position-relative overflow-hidden signup">
        <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="">
              <Zoom>
                <div className="signup_form card-view">

                  {/* <Container>
                    <div className="login-form">
                      <div className="login-welcome">Register With Us</div>
                      <div className="mt-3 mb-3">
                        <img alt='' className='metamask-logo' src="assets/img/metamask-2728406-2261817.png" />
                        <small className="text-white" style={{ fontSize: 12, padding: "0px 10px", fontWeight: "bold" }} >
                          {connectWalletAddress ? connectWalletAddress :
                            <span onClick={connectMetasmask} style={{ cursor: 'pointer' }}>Please connect metamask</span>}
                        </small>
                      </div>

                      <Form className="form-border" onSubmit={SubmitForm}>
                        <div className="field-set mb-3">
                          <label className="form-label">Email</label>
                          <input type="text" autoComplete="off" name="email" onChange={inputHandler} className="form-control" placeholder="Email" id='emailError' />
                          <span className="validationError">{validationError.emailError}</span>
                        </div>

                        <div className="field-set mb-3">
                          <label className="form-label">Password</label>
                          <input type="password" name="password" className="form-control" autoComplete="off" onChange={inputHandler} placeholder="Password" id='passwordError' />
                          <span className="validationError">{validationError.passwordError}</span>
                        </div>

                        <div className="field-set mb-3">
                          <label className="form-label">Confirm Password</label>
                          <input type="password" name="confirm_password" className="form-control" onChange={inputHandler} placeholder="Confirm Password" id='confirmPasswordError' />
                          <span className="validationError">{validationError.confirmPasswordError}</span>
                        </div>

                        <div className="field-set mb-3">
                          <label className="form-label">Referral id(optional)</label>
                          <input type="text" name="referral_address" className="form-control" onChange={inputHandler} value={form.referral_address} placeholder="Eg. 0x0000000000000000000" />
                          <span className="validationError">{validationError.walletAddressError}</span>
                        </div>

                        <div className="form-check d-flex mb-3 pl-2">
                          <input className="form-check-input me-2" id='termsconditionError' onChange={inputHandler} type="checkbox" name="termscondition" />
                          <label className="form-check-label" htmlFor="form2Example3g">
                            I agree to the
                            <a href={`${config.baseUrl}privacypolicy`}> terms &amp; conditions </a> and <a href={`${config.baseUrl}privacypolicy`}> privacy policy </a>
                          </label>
                        </div>
                        <span className="validationError">{validationError.termsconditionError}</span>

                        <div className="field-set mb-1 ">
                          {connectWalletAddress ?
                            <button type="submit" className="btn btn-primary w-100">
                              <span className="icon"> </span> Register Now
                            </button>
                            :
                            <button type="button" onClick={connectMetasmask} className="btn btn-primary w-100">
                              <span className="icon"> </span> Connect Wallet
                            </button>
                          }
                          <div className="text-center">
                            Already have an account? <a className="hoverColor" href={`${config.baseUrl}login`}>Login</a>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Container> */}
                  <Container>
                    <div className="login-form">
                      <div className="login-welcome">Register With Us</div>
                      <div className="mt-3 mb-3 d-flex">
                        <img alt='' className='metamask-logo' src="assets/images/icon/metamask-logo.png" width="20px" height="20px" />
                        <small className="text-white text-break" style={{ padding: "0px 10px", fontWeight: "bold" }} >
                          {connectWalletAddress ? connectWalletAddress :
                            <span onClick={connectMetasmask} style={{ cursor: 'pointer' }}>Please connect your Metamask/Trust Wallet</span>}
                        </small>
                      </div>

                      <Form className="form-border" onSubmit={SubmitForm}>
                        <Form.Group className="field-set mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" autoComplete="off" name="email" onChange={inputHandler} placeholder="Enter your email address" id='emailError' />
                          <span className="validationError">{validationError.emailError}</span>
                        </Form.Group>

                        <Form.Group className="field-set mb-3">
                          <Form.Label>Password</Form.Label>
                          <Form.Control type="password" name="password" onChange={inputHandler} autoComplete="off" placeholder="Enter your Password" id='passwordError' />
                          <span className="validationError">{validationError.passwordError}</span>
                        </Form.Group>

                        <Form.Group className="field-set mb-3">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control type="password" name="confirm_password" onChange={inputHandler} placeholder="Enter Confirm Password" id='confirmPasswordError' />
                          <span className="validationError">{validationError.confirmPasswordError}</span>
                        </Form.Group>

                        <Form.Group className="field-set mb-3">
                          <Form.Label>Referral id(optional)</Form.Label>
                          <Form.Control type="text" name="referral_address" onChange={inputHandler} value={form.referral_address} placeholder="Eg. 0x0000000000000000000" />
                          <span className="validationError">{validationError.walletAddressError}</span>
                        </Form.Group>

                        <div className="form-check d-flex mb-3 pl-0">
                          {/* <Form.Check
                            className="form-check-input me-2"
                            id='termsconditionError'
                            onChange={inputHandler}
                            type="checkbox"
                            name="termscondition"
                          /> */}
                          {/* <input
                            type="checkbox"
                            id='termsconditionError'
                            checked={isChecked}
                            onChange={inputHandler}
                            name="termscondition"
                          /> */}
                          {/* <Col lg={12} className="">
                            <FormGroup>
                              <label class="container_checkbox">I agree to the <a href={`${config.homeurl}tos`}> terms &amp; conditions </a> and <a href={`${config.homeurl}privacypolicy`}> privacy policy </a>
                                <Form.Control
                                   id='termsconditionError'
                                   onChange={inputHandler}
                                   type="checkbox"
                                   name="termscondition"
                                />
                                <span class="checkmark"></span>
                              </label>
                            </FormGroup>

                          </Col> */}
                        </div>
                        {/* <span className="validationError">{validationError.termsconditionError}</span> */}


                        <div className="field-set mb-2 mt-4 ">
                          {connectWalletAddress ? (
                            <Button type="submit" variant="main2" className="w-100 text-white">
                              <span className="icon"> </span> Register Now
                            </Button>
                          ) : (
                            <Button type="button" onClick={connectMetasmask} variant="main2" className="w-100 text-white">
                              <span className="icon"> </span> Connect Wallet
                            </Button>
                          )}
                          {/* <div className="text-center">
                            Already have an account? <a className="hoverColor" href={`${config.baseUrl}login`}>Login</a>
                          </div> */}
                        </div>
                      </Form>
                    </div>
                  </Container>
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Signup;
