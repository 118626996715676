import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import {
  exportLoyaltyBounsData,
  MatchingTeamAndNodeList,
  ShowReferralHistory,
  showsteplevelrewardAction,
} from "../Action/user.action";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";

const Matchingbonus = () => {
  const [getreferral, setreferral] = useState([]);
  const [getnodelist, setnodelist] = useState([]);
  const [getteamlist, setteamlist] = useState([]);
  const [royaltybonuslist, setroyaltybonuslist] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  const [getreferralcount, setreferralcount] = useState(0);
  const [gettotalamountmnt, settotalamountmnt] = useState("");
  const [gettotalamountusdt, settotalamountusdt] = useState("");
  const [teamPurchase, setteamPurchase] = useState(0);
  const [totalEarening,setTotalEarening] = useState(0)

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  const isOnFirstPage = currentPage == 0;
  const totalPages = Math.ceil(getreferral.length / itemsPerPage);
  const isOnLastPage = currentPage == totalPages - 1;
  const totalPagesNodeList = Math.ceil(getnodelist.length / itemsPerPage);
  const isOnLastPageNodeList = currentPage == totalPagesNodeList - 1;
  const totalPagesteamlist = Math.ceil(getteamlist.length / itemsPerPage);
  const isOnLastPageteamlist = currentPage == totalPagesteamlist - 1;
  const totalPagesroyaltybonuslist = Math.ceil(
    royaltybonuslist.length / itemsPerPage
  );
  const isOnLastPageroyaltybonuslist =
    currentPage == totalPagesroyaltybonuslist - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    ReferralFunc();
    showsteplevelFunc();
    showmatchingandnodelistFunc();
  }, []);

  const getreferralSliced = getreferral?.slice(startIndex, endIndex);
  const getnodelistSlice = getnodelist?.slice(startIndex, endIndex);
  const getteamlistSlice = getteamlist?.slice(startIndex, endIndex);
  const royaltybonuslistSlice = royaltybonuslist?.slice(startIndex, endIndex);
  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const showsteplevelFunc = async () => {
    let res2 = await showsteplevelrewardAction();
    if (res2.success) {
      setroyaltybonuslist(res2.data);
    } else {
    }
  };

  const showmatchingandnodelistFunc = async () => {
    let res1 = await MatchingTeamAndNodeList();
    if (res1.success) {
      setnodelist(res1.data.nodeList);
      setteamlist(res1.data.teamList);
    } else {
    }
  };

  const ReferralFunc = async () => {
    let res = await ShowReferralHistory(2);
    // let res2 = await showsteplevelrewardAction();
    // let res1 = await MatchingTeamAndNodeList();
    if (res.success) {
      setreferral(res.data.history);
      setreferralcount(res.data.statistics.TeamCount);
      setteamPurchase(res.data.statistics.teamPurchase);
      setTotalEarening(res.data.statistics.totalmatchingearning || 0)
      let totalamount = 0;
      for (let i = 0; i < res.data.history.length; i++) {
        totalamount += parseFloat(res.data.history[i].amount);
      }
      settotalamountmnt(totalamount);
      let totalamountUSDT = 0;
      for (let i = 0; i < res.data.history.length; i++) {
        totalamountUSDT += parseFloat(res.data.history[i].usdAmount);
      }
      settotalamountusdt(totalamountUSDT);
    } else {
      setreferral([]);
    }
  };

  const handleExportCsv = async (date) => {
    setCsvLoading(true);
    const swalWait = Swal.fire({
      title: "Please wait...",
      text: "Your File is loading.",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let res = await exportLoyaltyBounsData({ date });
    if (res.success) {
      setCsvLoading(false);
      Swal.close();
      // Convert the data into a CSV format
      const csvData = res.data;
      const csvContent = [
        Object.keys(csvData[0]), // Get the headers (keys)
        ...csvData.map((row) => Object.values(row)), // Map values for each row
      ];

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent.map((e) => e.join(",")).join("\n")], {
        type: "text/csv;charset=utf-8;",
      });

      // Create a link element, set URL using blob, and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "loyalty-bonus.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Failed to fetch data");
      setCsvLoading(false);
      Swal.close();
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-3">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h2 className="text-white">Community Rewards </h2>
                </Col>
              </Row>
            </Container>
          </div>
          {/* -------------------- */}

          <div className=" mb-5">
            <div className="container referral">
              <div className="mr-referral-content">
                <div className="mr-referral-block">
                  <Row>
                    <Col lg={5} md={12} className="">
                      <div
                        className="mr-referral-carditem mr-referralref-item buyform card-view1 p-5 px-3 mb-4"
                        style={{ minHeight: "348px" }}
                      >
                        <div className="mr-referral-inner">
                          <div className="mr-contant-block">
                            <h4>
                            
                              <Row>
                                <Col lg={5} sm={5} xs={5}>
                                  {" "}
                                  Team Size
                                </Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex">
                                  <span className="seprator"> : </span>
                                  <span className="text">{getreferralcount}</span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>
                            <h4>
                             
                              <Row>
                                <Col lg={5} sm={5} xs={5}>
                                  {" "}
                                  Team Purchase
                                </Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex">
                                  <span className="seprator"> : </span>
                              <span className="text">
                                ${teamPurchase == null ? 0 : teamPurchase}
                              </span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>
                            <h4 className="mb-0">
                             
                              <Row>
                                <Col lg={5} sm={5} xs={5}>
                                  {" "}
                                  Total Income
                                </Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex">
                                  <span className="seprator"> : </span>
                              <span className="text text-white">
                                {/* $0.00 */}$
                                {totalEarening}
                                {/* {parseFloat(gettotalamountusdt)?.toFixed(2)} */}
                              </span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={7} md={12} className=" ">
                      <div className="mr-referral-carditem mr-referral-item buyform mb-4 card-view1 p-4">
                        <div className="mr-contant-block">
                          <h4>Community Rewards</h4>
                          <img src="assets/images/mlm_tree.png" width="100%" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          {/* ---------- */}
          <div className="latest_transaction mb-5">
            <Container>
              <Tab.Container id="left-tabs-example" defaultActiveKey="teamlist">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="teamlist"
                          className="card-view1 customnav-link"
                          onClick={CurrentPageResetFunc}
                        >
                          Team List
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          eventKey="nodes"
                          className="card-view1 customnav-link"
                          onClick={CurrentPageResetFunc}
                        >
                          Nodes
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          eventKey="bonushistory"
                          className="card-view1 customnav-link"
                          onClick={CurrentPageResetFunc}
                        >
                          Bonus History
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          eventKey="royaltybonus"
                          className="card-view1 customnav-link"
                          onClick={CurrentPageResetFunc}
                        >
                          Loyalty Bonus
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content>
                      <Tab.Pane eventKey="teamlist">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th style={{ width: "100px" }}>S.No</th>
                                <th>Email</th>
                                <th>Referred By</th>
                                <th>Purchase</th>
                                <th>Joining Date</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {getteamlist.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="5">No Team Available</td>
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                getteamlistSlice.map((item, index) => (
                                  <tr className="tr_list">
                                    <td style={{ width: "100px" }}>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.email}</td>
                                    <td>{item.referred_by}</td>
                                    <td>${item.user_purchase}</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {getteamlist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageteamlist ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageteamlist ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  getteamlist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="nodes">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th style={{ width: "100px" }}>S.No</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Total Business</th>
                                <th>Remaining Amount</th>
                                <th>Joining Date</th>
                                {/* <th>Team List</th> */}
                              </tr>
                            </thead>
                            <tbody className="">
                              {getnodelist.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="6"> No Data Available</td>
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                getnodelistSlice.map((item, index) => (
                                  <tr className="tr_list">
                                    <td style={{ width: "100px" }}>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.email}</td>
                                    <td>
                                      {" "}
                                      <span className="text-gradient">
                                        <b>
                                          {" "}
                                          <span class="text-sm text-bold text-light">
                                            {item.bnb_address?.slice(0, 4) +
                                              "...." +
                                              item.bnb_address?.slice(-4)}{" "}
                                          </span>
                                          <CopyToClipboard
                                            text={item.bnb_address}
                                          >
                                            <span
                                              title="Click to Copy"
                                              className="mr-copylink"
                                              id="token-buy-button"
                                              onClick={copieBtn}
                                              style={{
                                                cursor: "pointer",
                                                color: "rgb(157 81 255)",
                                              }}
                                            >
                                              <i class="fa fa-clone "></i>
                                            </span>
                                          </CopyToClipboard>
                                        </b>
                                      </span>
                                    </td>
                                    <td>${item.totalBusiness}</td>
                                    <td>${item.remainingBalance}</td>
                                    <td>{item.created_at}</td>
                                    {/* <td><Button variant="main2">View</Button></td> */}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {getnodelist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageNodeList ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageNodeList ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  getnodelist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="bonushistory">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th style={{width:"100px"}} >S.No</th>
                                <th>Date</th>
                                <th>Email</th>
                                <th>NFT Id</th>
                                <th>Community Rewards %</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {getreferral.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="6">No History Available</td>
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                getreferralSliced.map((item, index) => (
                                  <tr className="tr_list">
                                    <td style={{ width: "100px" }}>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.datetime}</td>
                                    <td>{item.user}</td>
                                    <td>{item.uniqueid}</td>
                                    <td>
                                      {item.referralPercent == null
                                        ? "NA"
                                        : item.referralPercent}
                                      %
                                    </td>
                                    <td>
                                      ${parseFloat(item.usdAmount)?.toFixed(2)}~
                                      {parseFloat(item.amount)?.toFixed(2)}
                                      &nbsp;MNT
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {getreferral.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPage ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPage ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  getreferral.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="royaltybonus">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th style={{ width: "100px" }}>S.No</th>
                                <th>Date</th>
                                <th>Total NFT</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {royaltybonuslist.length == 0 ? (
                                <tr className="tr_list">
                                  <td colspan="6">No History Available</td>
                                </tr>
                              ) : (
                                royaltybonuslistSlice.map((item, index) => (
                                  <tr className="tr_list">
                                    <td style={{ width: "100px" }}>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.date?.slice(0, 10)}</td>
                                    <td>{item.count}</td>
                                    <td>
                                      {parseFloat(item.amount).toFixed(4)}
                                      &nbsp;MNT
                                    </td>
                                    <td>
                                      {/* <CSVLink data={[]} className="text-nowrap pl-2 "> */}
                                      <button
                                        onClick={() =>
                                          handleExportCsv(item.date)
                                        }
                                        className="btn-main2 px-4"
                                        style={{
                                          cursor: "pointer",
                                          minHeight: 35,
                                        }}
                                      >
                                        Export CSV
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {royaltybonuslist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageroyaltybonuslist
                                    ? "btn  disabled"
                                    : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageroyaltybonuslist
                                    ? "disabled-link"
                                    : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  royaltybonuslist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default Matchingbonus;
