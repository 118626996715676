import React, { createContext,  useEffect, useState } from "react";

// Step 1: Create a context to manage the body class
export const UserContext = createContext();
// Step 2: Create a context provider component
export function UserProvider({ children }) {
    const [viewbalance, setviewbalance] = useState(false); // Initialize user state  
    return (
      <UserContext.Provider value={{viewbalance , setviewbalance}}>
        {children}
      </UserContext.Provider>
    );
  }